<template>
  <div class="md-topics-pages-component">
    <div class="md-topic-container">
      <div class="md-topic-type-box">
        <el-tree
          class="tree"
          :data="categoryAryList"
          highlight-current
          :props="{ children: 'categoryList', label: 'name' }"
          @node-click="menuClick"
          :default-expanded-keys="defaultExpandedKeys"
          :expand-on-click-node="false"
          ref="tree"
          node-key="rid"
        >
        </el-tree>
      </div>
      <div class="md-topic-list-box">
        <div class="search-box">
          <div>
            <img
              src="@/assets/img/total.png"
              style="width: 24px; height: 24px"
              alt=""
            />
            <img
              src="@/assets/img/lianxi.png"
              alt=""
              style="width: 79px; height: 18px"
            />
          </div>
          <div class="search-item">
            <el-input
              class="w-280"
              v-model="params.searchValue"
              placeholder="四六级真题"
              prefix-icon="el-icon-search"
              :clearable="true"
            >
              <el-button
                slot="append"
                class="search"
                @click="selectAnswerList(1)"
                >搜索</el-button
              >
            </el-input>
          </div>
        </div>
        <div class="topic-list-panel">
          <ul class="topic-list" v-if="topicsListAry.length">
            <li
              class="topic-item"
              v-for="item in topicsListAry"
              :key="item.rid"
            >
              <!-- <div class="t-img">
                <img :src="`/video-dev${item.cover}`" :alt="item.title">
              </div> -->
              <div class="t-right">
                <div class="t-tit-box">
                  <p class="t-tit">{{ item.title }}</p>
                  <!-- <p class="t-type">
                    <span>上传时间：{{ item.createTime }}</span>
                    <span>类型：{{ item.type }}</span>
                  </p> -->
                </div>
                <div class="q-btn">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="turnTopicDetail(item)"
                    >答题</el-button
                  >
                  <el-button size="mini" @click="download(item)"
                    >下载</el-button
                  >
                </div>
              </div>
            </li>
          </ul>
          <p v-else class="no-data">暂无数据</p>
          <!-- 分页 -->
          <div class="pagination" v-if="topicsListAry.length > 0">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="+params.pageNum"
              :page-size="params.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectAnswerList } from "@/axios/topics.js";
import { getCategoryList } from "@/axios/course";
// import { saveAs } from 'file-saver'
export default {
  data() {
    return {
      inputVal: "",
      defaultActive: "",
      categoryAryList: [],
      topicsListAry: [],
      params: {
        categoryId: null,
        searchValue: "",
        pageNum: 1,
        pageSize: 10,
      },
      defaultExpandedKeys: [],
      total: 0,
    };
  },
  computed: {
    isLogin() {
      return window.localStorage.getItem("isLogin");
    },

    userInfo() {
      return JSON.parse(window.localStorage.getItem("userInfo"));
    },
  },
  created() {
    this.getCategoryList();
  },
  methods: {
    handleReset() {
      this.params.searchValue = "";
      this.selectAnswerList(1);
    },
    async getCategoryList() {
      const res = await getCategoryList(2);
      if (res.code === 200) {
        this.categoryAryList = res.data;
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(this.categoryAryList[0].rid);
          // 设置展开所有行
          const nodesMap = this.$refs.tree.store.nodesMap;
          const keys = Object.keys(nodesMap);
          keys.forEach((key) => {
            nodesMap[key].expanded = true;
          });

          // this.$refs.elMenu.open(this.categoryAryList[0].rid)
          // this.defaultActive = this.categoryAryList[0].categoryList[0].rid + ''
          this.params.categoryId = this.categoryAryList[0].rid;
          this.selectAnswerList(1);
        });
      }
    },
    async selectAnswerList(pNo) {
      this.params.pageNum = pNo;
      const res = await selectAnswerList(this.params);
      if (res.code === 200) {
        this.topicsListAry = res.rows;
        this.total = res.total;
      }
    },
    // 子类点击
    menuClick(item) {
      this.params.categoryId = item.rid;
      this.selectAnswerList(1);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.params.categoryId = key;
      this.selectAnswerList(1);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
      this.defaultActive = "-1";
    },
    menuSelect(index, indexPath) {
      console.log(123, index, indexPath);
    },
    handleCurrentChange(val) {
      this.selectAnswerList(val);
    },
    turnTopicDetail(item) {
      if (this.isLogin) {
        window.open(`/topicDetail?topicId=${item.rid}`);
      } else {
        this.$confirm("是否进行账号登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {});
      }
    },
    blobValidate(data) {
      return data.type !== "application/json";
    },
    async download(item) {
      if (this.isLogin) {
        if (item.doc !== null) {
          let url = `/video-dev${item.doc}`;
          const a = document.createElement("a");
          a.href = url;
          a.download = item.title; // 下载后文件名
          a.style.display = "none";
          document.body.appendChild(a);
          a.click(); // 点击下载
          document.body.removeChild(a); // 下载完成移除元素
        } else {
          this.$message.error("暂无源题");
        }
      } else {
        this.$confirm("是否去登录", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="less" scoped>
.md-topics-pages-component {
  width: 100%;
  padding: 30px 0;

  // margin-top: 100px;
  // background: #eceff5;
  .md-topic-container {
    display: flex;
    width: 1152px;
    margin: 0px auto;

    .md-topic-type-box {
      width: 260px;
      height: 550px;
      background: #fff;
      border-radius: 4px;
      margin-right: 20px;
      flex-shrink: 0;
      overflow-y: auto;
      .scroll-bar(5px, 5px);
      padding: 10px;
      box-sizing: border-box;
    }

    .md-topic-list-box {
      flex: 1;
      display: flex;
      flex-direction: column;

      .search-box {
        height: 80px;
        width: 100%;
        border-radius: 4px;
        background: #fff;
        position: relative;
        border-bottom: 1px solid #f0f0f0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;

        .search-item {
          position: absolute;
          right: 10px;
          display: flex;
          align-items: center;
          margin-right: 40px;
    
          .search-label {
            font-size: 14px;
            color: #333;
          }
        }

        .search-btn {
          display: inline-block;
        }
      }

      .topic-list-panel {
        min-height: 600px;
        border-radius: 4px;
        background: #fff;
        padding: 20px 30px;
        box-sizing: border-box;
        overflow-y: auto;
        .scroll-bar(5px, 5px);

        .topic-list {
          width: 100%;

          .topic-item {
            padding: 20px 0;
            border-bottom: 1px solid rgb(230, 230, 230);
            display: flex;
            align-items: center;

            &:first-child {
              padding-top: 0;
            }

            .t-img {
              width: 100px;
              height: 100px;
              border-radius: 8px;
              overflow: hidden;
              margin-right: 20px;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .t-right {
              flex: 1;
              // height: 100px;
              display: flex;
              align-items: center;

              .t-tit-box {
                flex: 1;

                .t-tit {
                  font-size: 16px;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 500;
                  color: #333333;
                  line-height: 22px;
                }

                .t-type {
                  span {
                    color: #888;
                    font-size: 12px;
                    margin-right: 30px;
                  }
                }
              }

              .q-btn {
                margin-left: 10px;
                flex-shrink: 0;
              }
            }
          }
        }

        .pagination {
          margin-top: 20px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

.no-data {
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
}

.w-280 {
  width: 564px;
  height: 40px;
}

/deep/ .el-menu {
  border-right: none !important;
}

::v-deep {
  .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: @md-light-primary-color;
    border-radius: 6px;
  }

  .el-tree-node__content:hover {
    background-color: @md-light-primary-color;
  }
}

/deep/ .el-submenu__title:hover {
  background: rgba(37, 192, 167, 0.05);
}

/deep/ .el-menu-item:focus,
.el-menu-item:hover {
  background: rgba(37, 192, 167, 0.05);
}

/deep/ .el-menu-item.is-active {
  // color: @md-primary-color;
  color: #333;
}

/deep/ .el-pager li.active {
  color: @md-primary-color;
}

/deep/ .el-pager li:hover {
  color: @md-primary-color;
}

/deep/ .el-input__inner:focus {
  border-color: transparent !important;
  border-right: none;
  .el-input-group__append {
    border-color: @md-primary-color !important;
  }
}

/deep/ .el-pagination button:hover {
  color: @md-primary-color;
}

/deep/ .el-button--primary {
  // border-color: @md-primary-color !important;
  // background: @md-primary-color;
  background-color: @md-primary-color;
  border-color: @md-primary-color;
  color: #fff;
  border-radius: 16px;
  width: 68px;
  height: 32px;
}

::v-deep {
  .el-button--default {
    border-radius: 16px;
    background-color: @md-light-primary-color;
    color: @md-primary-color;
    border-color: @md-light-primary-color;
    width: 68px;
    height: 32px;
  }

  .el-input__icon {
    color: @md-primary-color;
  }

  .el-input__prefix {
    left: 20px;
  }

  .el-input--prefix .el-input__inner {
    border-color: transparent !important;
    background-color: #fff9f0;
    padding-left: 48px;
    height: 41px;
    border-radius: 100px 0 0 100px;
  }
  .el-input__suffix {
    right: 0;
  }
  .el-input-group__append {
    background-color: @md-primary-color;
    color: #fff;
    width: 68px;
    height: 41px;
    border-radius: 0px 100px 100px 0px;
    border: 1px solid @md-primary-color;
  }
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: @md-primary-color;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    border: 1px solid #cccccc;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
    background-color: #fff;
  }
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    height: 36px;
    line-height: 36px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: @md-primary-color;
  }
}

/deep/ .el-button:focus,
.el-button:hover {
  // color: @md-primary-color;
  // border-color: rgba(37, 192, 167, 0.3);
  // background: rgba(37, 192, 167, 0.1);
}
</style>
